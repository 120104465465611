import * as React from 'react';
import Button from '@mui/material/Button';

function ButtonUsage() {

  const handleOnClick = () => {

    console.log('Clicked')
  }


  return <Button onClick={handleOnClick} style={{background:'lightBlue', border:'solid 1px grey', boxShadow:'3px 3px 10px grey', margin: 20, color:"whiteSmoke"}} variant="contained">Hello world</Button>;
}

export default ButtonUsage;