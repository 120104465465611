import './App.css';

import Button from "./MUIComponents/Button";
import Ttable from "./MUIComponents/Ttable";





function App() {







  return (
    <div>
      <h1 style={{color:'white', textShadow:'5px 4px 7px grey'}}>MAIN APP</h1>
      <Button />
      <Ttable />

    </div>
  );
}

export default App;
